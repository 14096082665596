.item_bshop {
  display: inline-flex;
  background: #f3f3f3;
  border-radius: 5px;
  /* padding-bottom: 7px; */
  -webkit-box-shadow: 0px 0px 8px 2px rgba(34, 60, 80, 0.16);
  -moz-box-shadow: 0px 0px 8px 2px rgba(34, 60, 80, 0.16);
  box-shadow: 0px 6px 7px 1px rgb(34 60 80 / 16%);
  /* text-align: center; */
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  border-radius: 40px 5px 5px 5px;
  border: 0.4px solid transparent;
  background-image: linear-gradient(#f3f3f3, #f3f3f3), linear-gradient(to right, #f4a582, #d432d8);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}
.item_bshop {
  display: inline-block;
  background: #f3f3f3;
  border-radius: 5px;
  /* padding-bottom: 7px; */
  -webkit-box-shadow: 0px 0px 8px 2px rgba(34, 60, 80, 0.16);
  -moz-box-shadow: 0px 0px 8px 2px rgba(34, 60, 80, 0.16);
  box-shadow: 0px 6px 7px 1px rgb(34 60 80 / 16%);
  /* text-align: center; */
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  border-radius: 40px 5px 5px 40px;
  border: 0.4px solid transparent;
  background-image: linear-gradient(#ffffff, #ffffff), linear-gradient(to right, #f4a582, #d432d8);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

/* .item_avatar_image{

} */

.item_avatar_image img {
  width: 64px;
  height: 64px;
  margin-top: 10px;
  float: left;
  margin-right: 15px;
  margin-left: 15px;
  border-radius: 50%;
}

.item__bshop_image {
  position: relative;
  /* margin-left: 4px; */
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-radius: 50%;
  width: 66px;
  height: 66px;
  /* margin-top: 3px; */
  /* margin-bottom: 3px; */
  margin: auto;
  margin-left: 4px;
  margin-top: 3px;
  margin-bottom: 3px;
  float: left;
}

.item__bshop_image img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  /* margin-top: 4px; */
  margin-left: 1px;
  margin-top: 1px;
}

.item__bshop_favorite,
.item__bshop_geo {
  position: absolute;
  background: rgba(243, 243, 243, 0.7);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  left: 78%;
  top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
}
.color {
  color: orange;
}
.item__bshop_favorite:active,
.item__bshop_geo:active {
  opacity: 0.3;
}

.item__bshop_geo {
  top: 74%;
}

.item__bshop_name {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  color: #404246;
  width: 250px;
  text-align: left;
  padding-left: 20px;
  /* padding-top: 35px; */
  /* display: revert; */
  margin-left: 75px;
  margin-top: 10px;
}

.svg_icon {
  width: 13px;
  margin-right: 4px;
}

.master_short_inf {
  font-size: 10px;
  text-align: left;
  margin-top: 10px;
  display: flex;
}

.subscrib {
  margin-left: 10px;
}

.completed {
  margin-left: 10px;
}

.rating {
  margin-left: 10px;
}

.item__bshop_size_price {
  display: flex;
  justify-content: space-between;
}

.sizes {
  /* display: flex;
  justify-content: space-between; */
  /* width: 64px; */
  width: 27px;
  margin: 0 8px;
  /* flex-wrap: wrap; */
}
.size {
  display: inline-block;
  text-align: center;
  padding-top: 4px;
  margin-right: 5px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  width: 16px;
  height: 16px;
  border: 0.4px solid #404246;
  border-radius: 2px;
  color: #404246;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 300;
  font-size: 8px;
  line-height: 8px;
  cursor: pointer;
}

.size:active {
  opacity: 0.1;
}

.price {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  color: #ff7a00;
  margin-right: 10px;
}

@media (max-width: 423px) {
  /* .item_bshop {
    display: block;
    margin: 0 auto 33px auto;
  }
  .item_bshop:nth-child(odd) {
    margin-right: auto;
  } */
}
@media (max-width: 377px) {
  .item__bshop_favorite,
  .item__bshop_geo {
    left: 70%;
  }
  .item__bshop_geo {
    top: 67%;
  }
}

@media (max-width: 353px) {
  .sizes {
    width: 28px;
  }
}
