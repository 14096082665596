.mainContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 26px auto 40px;
    gap: 10px;
}

.cardContainer{
    height: 100px;
    width: 100px;
    background-color: red;
}

@media screen and (min-width:478px) {
    .cardContainer{
        height: 135px;
        width: 135px;
    }
}

@media screen and (min-width:763px) {
    .cardContainer{
        height: 230px;
        width: 230px;
    }
}
