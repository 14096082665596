.root {
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  height: 80px;
  bottom: 0;
  background: #25272e;
  border: 1px solid #25272e;
  padding: 16px 44px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.root_beauty {
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  height: 80px;
  bottom: 0;
  background: #25272e;
  border: 1px solid #25272e;
  padding: 16px 44px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.save_root_beauty {
  box-sizing: border-box;
  position: fixed;
  width: 90%;
  left: 5%;
  height: 85px;
  bottom: 20px;
  background: #25272e;
  border: 1px solid #25272e;
  border-top-left-radius: 70px;
  border-top-right-radius: 70px;
  text-align: center;
  color: bisque;
  padding-top: 3px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.preview {
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  height: 57px;
  bottom: 0;
  background: #25272e;
  border: 1px solid #25272e;
  /* padding: 16px 44px; */
  text-align: center;
}

.footer__item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border: 2px solid #f3f3f3;
  border-radius: 50%;
}
.footer__item:active,
.profile:active {
  opacity: 0.5;
}

.cart_footer_item {
  position: relative;
}

.coun_items_cart {
  text-align: center;
  position: absolute;
  color: #ffff;
  width: 20px;
  background-color: #ff7a00;
  border-radius: 50px;
  left: 25px;
  bottom: 22px;
  opacity: 0.8;
}

.profile img {
  width: 100%;
  height: 100%;
  border-radius: 50px;
}

.beauty_button_confirm {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 132px;
  height: 32px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #f3f3f3;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  box-shadow: 1px 1px 4px rgba(64, 66, 70, 0.2);
  border-radius: 5px;
  border: 1px solid #e5e5e59e;
  margin-bottom: 15px;
}

.beauty_button_deleteReq {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 132px;
  height: 32px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #f3f3f3;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  box-shadow: 1px 1px 4px rgba(64, 66, 70, 0.2);
  border-radius: 5px;
  border: 1px solid #e5e5e59e;
  margin-top: 10px;
}

.modal {
  font-size: 13px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  /* border-radius: 20px; */
  color: #a8a8a8;
  width: 90%;
  text-align: center;
  margin: auto;
  height: 450px;
  /* margin-top: 100px; */
  bottom: 80px;
  position: fixed;
  left: 5%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.header {
  width: 100%;
  /* border-bottom: 1px solid gray; */
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding: 5px;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.content {
  width: 100%;
  padding: 10px 15px;
  text-align: center;
  margin-top: 10px;
}
.actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.close {
  cursor: pointer;
  /* position: absolute; */
  display: block;
  padding-left: 320px;
  padding-top: 10px;
  line-height: 20px;
  right: 15px;
  top: 80px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #ffffff;
}

.close_main {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: 15px;
  top: 20px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #ffffff;
}

.SlippedRecordsNum {
  color: red;
  position: absolute;
  top: 15px;
  margin-right: -30px;
  text-shadow: #ff63df 1px 0 10px;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
}
