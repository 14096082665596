.item_bshop {
  /* display: inline-block; */
  background: #ffffff;
  border-radius: 5px;
  padding-bottom: 7px;
  -webkit-box-shadow: 0px 0px 8px 2px rgba(34, 60, 80, 0.16);
  -moz-box-shadow: 0px 0px 8px 2px rgba(34, 60, 80, 0.16);
  box-shadow: 0px 6px 7px 1px rgb(34 60 80 / 16%);
  /* margin-right: 29px; */
  text-align: center;
  margin: auto auto 30px auto;
}
.item_bshop:nth-child(odd) {
  /* margin-right: 24px; */
}

.item_publtext {
  width: auto;
  margin-top: 35px;
  margin-right: 20px;
  margin-left: 20px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #404246;
  text-align: justify;
  max-width: 270px;
}

/* .item_avatar_image{

} */

.item_avatar_image img {
  width: 64px;
  height: 64px;
  margin-top: 10px;
  float: left;
  margin-right: 15px;
  margin-left: 15px;
  border-radius: 50%;
}

.item__bshop_image {
  position: relative;
}

.item__bshop_image img {
  max-width: 292px;
  max-height: 392px;
}

.item__bshop_favorite,
.item__bshop_geo {
  position: absolute;
  background: rgba(243, 243, 243, 0.7);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  left: 78%;
  top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
}
.color {
  color: orange;
}
.item__bshop_favorite:active,
.item__bshop_geo:active {
  opacity: 0.3;
}

.item__bshop_geo {
  top: 74%;
}
.item__bshop_name {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 11px;
  color: #404246;
  margin: 10px 20px;
  margin-bottom: 13px;
  margin-left: -100px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  /* overflow: hidden; */
  display: inline;
  position: absolute;
}

.item__bshop_size_price {
  display: flex;
  justify-content: space-between;
}

.sizes {
  /* display: flex;
  justify-content: space-between; */
  /* width: 64px; */
  width: 27px;
  margin: 0 8px;
  /* flex-wrap: wrap; */
}
.size {
  display: inline-block;
  text-align: center;
  padding-top: 4px;
  margin-right: 5px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  width: 16px;
  height: 16px;
  border: 0.4px solid #404246;
  border-radius: 2px;
  color: #404246;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 300;
  font-size: 8px;
  line-height: 8px;
  cursor: pointer;
}

.size:active {
  opacity: 0.1;
}

.price {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  color: #ff7a00;
  margin-right: 10px;
}

@media (max-width: 423px) {
  /* .item_bshop {
    display: block;
    margin: 0 auto 33px auto;
  }
  .item_bshop:nth-child(odd) {
    margin-right: auto;
  } */
}
@media (max-width: 377px) {
  .item__bshop_favorite,
  .item__bshop_geo {
    left: 70%;
  }
  .item__bshop_geo {
    top: 67%;
  }
}

@media (max-width: 353px) {
  .sizes {
    width: 28px;
  }
}
