.react-calendar {
  width: 350px !important;
  max-width: 100% !important;
  background: transparent !important;
  border: none !important;
  font-family: Arial, Helvetica, sans-serif !important;
  line-height: 1.125em !important;
  border-radius: 10px !important;
  text-decoration: none !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.react-calendar--doubleView {
  width: 700px !important;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex !important;
  margin: -0.5em !important;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50% !important;
  margin: 0.5em !important;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  margin: auto !important;
  font-family: 'Gotham Pro Regular' !important;
}
.react-calendar button {
  margin: 0 !important;
  border: 1px solid transparent !important;

  outline: none !important;
  /* width: 40px !important; */
  /* height: 49.7px !important; */
}
.react-calendar button:enabled:hover {
  cursor: pointer !important;
}
.react-calendar__navigation {
  display: flex !important;
  height: 44px !important;
  margin-bottom: 1em !important;
  justify-content: center;
}
.react-calendar__navigation button {
  min-width: 111px !important;
  background: none !important;
  /* font-weight: 600 !important; */
  width: 100%;
  color: black;
}
.react-calendar__navigation button:disabled {
  background-color: #ffffff !important;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6 !important;
}
.react-calendar__month-view__weekdays {
  text-align: center !important;
  text-transform: uppercase !important;
  font-weight: bold !important;
  font-size: 0.75em !important;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em !important;
  background: linear-gradient(72deg, #f4a582 20%, #ff608d 51.56%, #fa00ff 100%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 0.75em !important;
  font-weight: bold !important;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000 !important;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575 !important;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em !important;
}
.react-calendar__tile {
  font-weight: 600 !important;
  max-width: 100% !important;
  padding: 10px 6.6667px !important;
  background: none !important;
  text-align: center !important;
  line-height: 16px !important;
  font-family: 'Gotham Pro Regular' !important;
  color: black;
}
.react-calendar__tile:disabled {
  border-radius: 100% !important;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%) !important;
  color: #fff !important;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  -webkit-text-fill-color: transparent !important;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%) !important;
  -webkit-background-clip: text !important;
  border: 1px solid #1616169e !important;
  border-radius: 100%;
}
.react-calendar__tile--now {
  background: linear-gradient(266.64deg, #3293d8 -0.07%, #60b6ff 51.49%, #82a1f4 99.93%) !important;
  border-radius: 100% !important;
  opacity: 0.3 !important;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  /* background: #ffffa9 !important; */
}
.react-calendar__tile--hasActive {
  border-radius: 100% !important;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%) !important;
  color: #fff !important;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff !important;
}
.react-calendar__tile--active {
  -webkit-text-fill-color: transparent !important;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%) !important;
  -webkit-background-clip: text !important;
  border: 1px solid #1616169e !important;
  border-radius: 100% !important;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  border-radius: 100% !important;
  /* background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%) !important; */
  color: #fff !important;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6 !important;
}

abbr[title] {
  text-decoration: none !important;
}
