.header,
.headerEdit {
  /* margin-top: 12px; */
  margin-left: 24px;
  display: flex;
  margin-right: 24px;
}
.headerEdit {
  display: block;
}
.headerEdit label {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.headerEdit span {
  margin-top: 10px;
  color: blue;
  font-family: var(--GothamProRegular);
}
.labelInfoMaster {
  margin-top: 10px;
  width: 50%;
}

.labelInfoMaster div {
  margin-bottom: 20px;
  min-width: 120px;
  padding-bottom: 5px;
}
.labelInfoMaster div:nth-child(3) {
  font-size: 15px;
}
.masterEdit {
  margin-top: 10px;
  width: 50%;
}
.masterEdit .name_person,
.balanceEdit {
  box-sizing: border-box;
  padding-bottom: 10px;
  border-bottom: 1px solid #bababa;
  width: 100%;
  display: inline;
}

.avatar,
.avatarEdit {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 89px;
  height: 89px;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-radius: 50px;
  margin-right: 16px;
}

.avatarEdit::after {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  content: '\002B';
  background: #ff608d;
  position: absolute;
  top: 70%;
  left: 79%;
  border: 2px solid #fff;
  border-radius: 50px;
  font-size: 19px;
  color: #fff;
}
.avatar img,
.avatarEdit img {
  overflow: hidden;
  height: 82px;
  width: 82px;
  border-radius: 50%;
  object-fit: cover;
}
.name_person {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  word-wrap: break-word;
  color: #404246;
}

.proffesion_person {
  font-family: var(--GothamProRegular);

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */
  margin-top: 10px;
  color: #404246;
}

.info_subscribes,
.info_edit_master {
  display: flex;
  margin-top: 26px;
  font-family: var(--GothamProRegular);
}
.info_edit_master {
  margin-top: 19px;
}

.info_subscribes div {
  display: flex;
  margin-right: 9px;
  font-size: small;
}

.icon_info_subscribes {
  margin-right: 6px;
}

.icon_info_balans {
  margin-right: 6px;
  margin-top: -5px;
}
.buttons_person {
  display: flex;
  margin: 0 23px;
  margin-top: 24px;
  margin-bottom: 16px;
  justify-content: space-between;
}

.button_subscribe,
.button_write,
.button_search {
  cursor: pointer;
  padding: 8px 16px;
  gap: 10px;
  font-family: var(--GothamProRegular);
  width: 138px;
  height: 32px;
  position: relative;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-radius: 5px;
  margin-right: 5px;
  color: #404246;

  box-shadow: 0px 0px 7px 1px #bababade;
}

.button_subscribe div,
.button_search div {
  position: absolute;
  background-color: var(--mainColor);
  height: 89%;
  top: 2px;
  width: 96.5%;
  left: 2px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .button_subscribe:active,
.button_write:active,
.button_search:active {
  opacity: 0.6;
} */

.button_write:active {
  opacity: 0.6;
}

.button_write {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #f3f3f3;
  opacity: 1;
}

.button_search {
  width: 55px;
}
.button_search div {
  width: 93%;
}

.content {
  margin: 0 24px;
  padding-bottom: 50px;
}
.content_address {
  display: flex;
  margin-top: 17px;
  margin-bottom: 28px;
  align-items: center;
}
.content_address_geo {
  margin-right: 10px;
}

.content_address_text {
  margin-right: 11%;
  color: #404246;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  border-bottom: 0.4px solid #c5bebe;
}

.name_tabs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.name_tabs {
  border-bottom: 1px solid;
  padding-bottom: 11px;
}
.name_tabs div {
  position: relative;
  text-align: center;
}

.client_name_tabs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.client_name_tabs {
  border-bottom: 1px solid;
  padding-bottom: 11px;
}
.client_name_tabs div {
  position: relative;
  text-align: center;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: bold;
  color: #404246;
}

.active_tab::before {
  position: absolute;
  content: '';
  width: 100%;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-radius: 5px;
  height: 5px;
  left: 0;
  top: 27px;
}

.nonactive_tab {
  cursor: pointer;
  opacity: 0.5;
}

.tab {
  margin-top: 24px;
}

.tab_blog {
  display: flex;
  flex-wrap: wrap;
}
.tab_blog div img {
  width: 100%;
  height: 100%;
}
.tab_blog div {
  width: calc(100% / 3 - 1px);
  height: 30vw;
}

.buttonBeauty {
  /* display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 132px;
  height: 32px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  margin-left: 20px;
  color: #f3f3f3;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  box-shadow: 1px 1px 4px rgb(64 66 70 / 20%);
  border-radius: 5px;
  border: 1px solid #e5e5e59e; */
  border: none;
  font-family: var(--GothamProRegular);
  background: none;
  color: black;
  margin: auto;
}

@media (max-width: 320px) {
  .header,
  .content {
    margin: 0 5px;

    margin-top: 15px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .button_subscribe,
  .button_write,
  .button_search {
    font-size: 12px;
  }
  .buttons_person {
    margin: 0 5px;
    margin-top: 24px;
    margin-bottom: 16px;
  }
}

.container {
  margin: auto;
  margin-top: 10px;
  width: 90%;
  font-weight: 200;
}

.container .nav {
  position: relative;
}
.container .nav a {
  color: #404246;
}
.container .nav li {
  display: inline;
  color: #aaaaaa;
  font-size: 11px;
  font-family: 'proxima_nova_bold';
  text-transform: lowercase;
  /* letter-spacing: 3px; */
  margin-left: 10px;
  /* border-bottom: 1px solid #aaaaaa; */
  padding-bottom: 10px;
  margin-right: 10px;
  font-family: 'Gotham Pro Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
}
a {
  margin: 0;
  padding-bottom: 10px;
}
.nav li:hover a {
  border-bottom: 4px solid transparent;
  border-image: linear-gradient(
      to left,
      rgb(212, 50, 216) -0.07%,
      rgb(255, 96, 141) 51.49%,
      rgb(244, 165, 130) 99.93%
    )
    1 / 1 / 0 stretch;
  color: #404246;
  font-weight: 800;
}

.ftime {
  border-bottom: 4px solid transparent;
  border-image: linear-gradient(
      to left,
      rgb(212, 50, 216) -0.07%,
      rgb(255, 96, 141) 51.49%,
      rgb(244, 165, 130) 99.93%
    )
    1 / 1 / 0 stretch;
  color: #404246;
  font-weight: 800 !important;
}

.uv {
  /* display: inline-flex; */
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #aaaaaa;
}

.myRequests {
  text-align: center;
  margin-top: 40px;
}

.exitButton {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  height: 280px;
}

.exitButton button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 132px;
  height: 32px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  /* margin-left: 20px; */
  color: #f3f3f3;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  box-shadow: 1px 1px 4px rgb(64 66 70 / 20%);
  border-radius: 5px;
  border: 1px solid #e5e5e59e;
}

.getApplicationsText {
  font-family: var(--GothamProRegular);
  margin-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #bababa;
  color: #404246;
  padding-left: 24px;
}

.root_add_balance {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20vh 24px;
  text-align: center;
}
.root_add_balance a {
  margin-top: 20px;
}
.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: 15px;
  top: 10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #ffffff;
}

.EditHeader {
  width: 100%;
  /* border-bottom: 1px solid gray; */
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding: 5px;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
}
.contents {
  width: 100%;
  padding: 10px 5px;
  text-align: center;
}

.modal {
  font-size: 14px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 20px;
  color: #424141;
  width: 355px;
  text-align: center;
  margin-top: 10px;
}

.input_label {
  display: flex;
  flex-direction: column;
  width: 270px;
  /* margin-left: 15px; */
  margin: auto;
  margin-top: 40px;
}

.input_label label {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 6px;
  color: #404246;
  opacity: 0.7;
}

.input_label input,
.input_label textarea {
  padding: 5px 10px;
  height: 40px;
  border: 1px solid#A8A8A8;
  border-radius: 5px;
  background: none;
  opacity: 0.7;
  margin-bottom: 20px;
}

.input_label label:focus,
.input_label input :focus {
  opacity: 1;
}

.beauty_button_confirm {
  display: initial;
  align-items: center;
  justify-content: center;
  width: 132px;
  height: 32px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #f3f3f3;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  box-shadow: 1px 1px 4px rgba(64, 66, 70, 0.2);
  border-radius: 5px;
  border: 1px solid #e5e5e59e;
  margin-bottom: 15px;
}

.conf {
  margin-top: 20px;
  border-radius: 5px;
  border: 0.4px solid transparent;
  background-image: linear-gradient(#ffffff, #ffffff),
    linear-gradient(to right, rgb(237, 205, 115), rgb(255, 1, 95));
  background-origin: border-box;
  background-clip: padding-box, border-box;
  height: 25px;
  width: 130px;
  text-align: center;
  padding-top: 6px;
}

.editProfile {
  margin: 0 20px;
}
.editProfile form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: auto 0;
  align-items: center;
}
.editProfile button {
  margin-top: 20px;
}

.editProfile input,
select {
  /* border: none !important;
  border-bottom: 1px solid #000 !important;
  border-radius: 0 !important; */
  font-size: 16px;
  /* padding: 12px 20px; */
  box-sizing: border-box;
  border: 0.5px solid #ccc;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
  border-radius: 5px;
  background: #f3f3f3;
}

.infoEdit .row {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  margin: 12px 0;
}

.infoEdit .row *:first-child {
  font-weight: bold;
}

.infoEdit .row .field {
  border-bottom: 1px solid #333;
}

.infoEdit .row *:nth-of-type(3) {
  background: #00000033;
}

.infoEdit {
  margin-top: 20px;
  border-top: 1px solid #bababa;
  border-bottom: 1px solid #bababa;
  margin: 20px 32px;
}

.dropdown_serv {
  border-radius: 5px;
  /* margin-left: 20px; */
  margin-bottom: 10px;

  width: 310px;
  height: 35px;

  color: #404246c7;
  padding-left: 20px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 0.5px solid #a8a8a8;
  background: transparent
    url("data:image/svg+xml;utf8,<svg viewBox='0 0 16 16' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m3.626 6.832A.5.5 0 0 1 4 6h8a.5.5 0 0 1 .374.832l-4 4.5a.5.5 0 0 1-.748 0l-4-4.5z' fill='black'/></g></svg>")
    no-repeat;
  background-position: right 5px top 50%;
}

.fakeHeader {
  height: 65px;
  position: absolute;
  width: 100%;
  top: -70px;
  background: #ffffff;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.fakeHeaderTitle {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  -webkit-background-clip: text;
  font-weight: 500;
  padding-top: 20px;
  font-size: 20px;
}

.BackButton {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin: auto;
}

.timeButton {
  border: 0.5px solid #a8a8a8;
  text-align: center;
  align-items: center;
  border: 1px solid #d1c7c79e;
  border-radius: 5px;
  color: #4a4141;
  font-size: 14px;
  font-style: normal;
  height: 32px;
  line-height: 14px;
  margin-left: 20px;
  width: 64px;
  align-items: center;
  font-family: var(--GothamProRegular);
  font-style: normal;
  text-align: center;
  padding-top: 8px;
}

.timeButtonsList {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(4, 81px);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 9px;
  margin-left: -11px;
}

.today {
  border: 0.5px solid #a8a8a8;
  text-align: center;
  align-items: center;
  border: 1px solid #d1c7c79e;
  border-radius: 5px;
  color: #4a4141;
  font-size: 14px;
  font-style: normal;
  height: 35px;
  line-height: 14px;
  margin-left: 20px;
  width: 84px;
  align-items: center;
  font-family: var(--GothamProRegular);
  font-style: normal;
  text-align: center;
  padding-top: 8px;
}

.tommorow {
  border: 0.5px solid #a8a8a8;
  text-align: center;
  align-items: center;
  border: 1px solid #d1c7c79e;
  border-radius: 5px;
  color: #4a4141;
  font-size: 14px;
  font-style: normal;
  height: 35px;
  line-height: 14px;
  margin-left: 20px;
  width: 84px;
  align-items: center;
  font-family: var(--GothamProRegular);
  font-style: normal;
  text-align: center;
  padding-top: 8px;
  margin-left: 0px;
}

.DatesList {
}

.DatesGroup {
  /* margin-bottom: 20px; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: -40px;
  grid-row-gap: 9px;
  margin-left: -11px;
  text-align: left;
}

.dropdown_date {
  border-radius: 5px;
  /* margin-left: 20px; */
  margin-bottom: 10px;

  width: 104px;
  height: 35px;

  color: #404246c7;
  padding-left: 5px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 0.5px solid #a8a8a8;
  background: transparent
    url("data:image/svg+xml;utf8,<svg viewBox='0 0 16 16' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m3.626 6.832A.5.5 0 0 1 4 6h8a.5.5 0 0 1 .374.832l-4 4.5a.5.5 0 0 1-.748 0l-4-4.5z' fill='black'/></g></svg>")
    no-repeat;
  background-position: right 5px top 50%;
}
