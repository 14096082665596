.MastersAndServicesWrapper {
  /* margin: 0px 15px; */
  position: relative;
  margin-bottom: 78px;
  background: #ffffff;
}

.filterInItem {
  display: flex;
  justify-content: space-between;
  margin: 0 27px;
  position: relative;
  margin-bottom: 24px;
}

.inputSearch {
  width: 71%;
  height: 40px;
  padding-left: 35px;
  border: 0.5px solid #a8a8a8;
  border-radius: 5px;
  background: none;
  margin-top: 16px;
}

.inputImgSearch {
  position: absolute;
  left: 12px;
  top: 10px;
  margin-top: 16px;
}

.dropdown_date_button {
  display: inline;
}
.dropdown_filters {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 40px;
  /* background: #ff7a00; */
  border-radius: 5px;
  margin-top: 16px;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-radius: 5px;
}

.dropdown_date {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 162px;
  height: 40px;
  /* background: #ff7a00; */
  margin: auto;
  border: 0.5px solid #a8a8a8;
  border-radius: 5px;
  background: none;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

input[type='time']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  cursor: pointer;
  display: block;
  width: 20px;
  color: red;
  text-align: center;
  position: relative;
}

.dropdown_time {
  width: 160px;
  height: 40px;
  border: 0.5px solid #a8a8a8;
  border-radius: 5px;
  background: none;
  margin: auto;
  font-family: var(--GothamProRegular);
  /* padding-left: 7px; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #404246;
  text-align: center;
}

.dropDownCalendar {
  text-align: center;
  margin-top: 15px;
}

.btnWrapper {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.shop_select {
  width: 100%;
}

.open_filter {
  display: flex;
  /* flex-direction: column; */
  /* margin: 0 27px; */
  text-align: center;
}

.trade_house_select,
.corpus_select,
.shop_select,
.product_filter_select {
  width: 160px;
  height: 40px;
  border: 0.5px solid #a8a8a8;
  border-radius: 5px;
  background: none;
  margin-bottom: 24px;
  font-family: var(--GothamProRegular);
  padding-left: 7px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #404246;
}

.filter_last_line {
  display: inline;
  margin-left: 20px;
}

.btnFilter {
  width: 161px;
  display: inline-block;
  margin-top: 10px;
}

.button_filter_svg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-left: 40px;
  border: 0.5px solid #404246;
  border-radius: 5px;
}

.choice_of_services {
  width: 50%;
  border-bottom: 0.5px solid #404246;
  text-align: center;
  display: inline;
}

.choice_of_masters {
  width: 50%;
  border-bottom: 0.5px solid #404246;
  text-align: center;
  display: inline;
}

.choice_of_services_and_masters {
  margin-top: 20px;
}

.beauty_button_confirm {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 40px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #f3f3f3;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  box-shadow: 1px 1px 4px rgba(64, 66, 70, 0.2);
  border-radius: 5px;
  border: 1px solid #e5e5e59e;
  margin-bottom: 15px;
}

.client_name_tabs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.client_name_tabs {
  border-bottom: 1px solid #afacac;
  padding-bottom: 11px;
  color: #727272;
}
.client_name_tabs div {
  position: relative;
  text-align: center;
}

.active_tab::before {
  position: absolute;
  content: '';
  width: 100%;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-radius: 5px;
  height: 5px;
  left: 0;
  top: 27px;
}
