.item_bshop {
  display: inline-flex;
  background: #f3f3f3;
  border-radius: 5px;
  /* padding-bottom: 7px; */
  -webkit-box-shadow: 0px 0px 8px 2px rgba(34, 60, 80, 0.16);
  -moz-box-shadow: 0px 0px 8px 2px rgba(34, 60, 80, 0.16);
  box-shadow: 0px 6px 7px 1px rgb(34 60 80 / 16%);
  /* text-align: center; */
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  border-radius: 40px 5px 5px 40px;
  border: 0.4px solid #404246;
}
.item_bshop:nth-child(odd) {
  display: inline-flex;
  background: #f3f3f3;
  border-radius: 5px;
  /* padding-bottom: 7px; */
  -webkit-box-shadow: 0px 0px 8px 2px rgba(34, 60, 80, 0.16);
  -moz-box-shadow: 0px 0px 8px 2px rgba(34, 60, 80, 0.16);
  box-shadow: 0px 6px 7px 1px rgb(34 60 80 / 16%);
  /* text-align: center; */
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  border-radius: 40px 5px 5px 40px;
  border: 0.4px solid #404246;
}

.item__bshop_image {
  position: relative;
  /* margin-left: 4px; */
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-radius: 50%;
  width: 66px;
  height: 66px;
  /* margin-top: 3px; */
  /* margin-bottom: 3px; */
  margin: auto;
  margin-left: 9px;
  margin-top: 3px;
  margin-bottom: inherit;
}

.item__bshop_image img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  /* margin-top: 4px; */
  margin-left: 0px;
  margin-top: 3px;
}

.beauty_button_fin {
  width: 170px;
  height: 30px;
  /* background: #ff7a00; */
  /* border: 1px solid #e5e5e59e; */
  /* border-radius: 5px; */
  /* background: none; */
  font-family: var(--GothamProRegular);
  font-style: oblique;
  font-weight: 600;
  font-size: 16px;
  margin-top: -40px;
  margin-left: 175px;
  /* border-radius: 5px; */
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  /* color: #612828; */
  /* margin-bottom: 20px; */
  /* border-radius: 5px; */
  /* border: 0.4px solid transparent; */
  /* background-image: linear-gradient(white, rgb(183, 193, 188)), linear-gradient(to right, rgb(237, 205, 115), rgb(255, 1, 95)); */
  /* background-origin: border-box; */
  /* background-clip: padding-box, border-box; */
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.beauty_button_refused {
  width: 140px;
  height: 30px;
  /* background: #ff7a00; */
  /* border: 1px solid #e5e5e59e; */
  /* border-radius: 5px; */
  /* background: none; */
  font-family: var(--GothamProRegular);
  font-style: oblique;
  font-weight: 600;
  font-size: 16px;
  margin-top: -40px;
  margin-left: 209px;
  /* border-radius: 5px; */
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  /* color: #612828; */
  /* margin-bottom: 20px; */
  /* border-radius: 5px; */
  /* border: 0.4px solid transparent; */
  /* background-image: linear-gradient(white, rgb(183, 193, 188)), linear-gradient(to right, rgb(237, 205, 115), rgb(255, 1, 95)); */
  /* background-origin: border-box; */
  /* background-clip: padding-box, border-box; */
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.item_beauty_image {
  position: relative;
  /* margin-left: 4px; */
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-radius: 50%;
  width: 70px;
  height: 70px;
  /* margin-top: 3px; */
  /* margin-bottom: 3px; */
  margin: auto;
  margin-left: 9px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.item_beauty_image img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  /* margin-top: 4px; */
  margin-left: 1px;
  margin-top: 3px;
}

.item_master_serv_image {
  position: relative;
  /* margin-left: 4px; */
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-radius: 50%;
  width: 68px;
  height: 68px;
  /* margin-top: 3px; */
  /* margin-bottom: 3px; */
  margin: auto;
  margin-left: 4px;
}

.item_master_serv_image img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  /* margin-top: 4px; */
  margin-left: 2px;
  margin-top: 2px;
}

.item__bshop_name {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #404246;
  width: 140px;
  text-align: left;
  padding-left: 10px;
  padding-top: 8px;
}

.item_client_req_info {
  width: 262px;
}

.item_client_req_info_name_id {
  display: block;
  align-items: center;
  justify-content: space-between;
  margin-top: 2px;
}

.item_client_req_info_comm_price {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin-top: 4px;
}

.item_client_req_info_nameClient {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #404246;
  width: 100px;
  margin-left: 15px;
  line-height: 18px;
}

.item_client_req_info_OrderNomer {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #404246;
  width: 80px;
  margin-left: 15px;
}

.item_master_req_info_Order {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #404246;
  width: 190px;
  margin-left: 15px;
  margin-top: 6px;
}

.item_client_req_info_date {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #404246;
  width: 70px;
  margin-left: 10px;
  text-align: left;
  /* margin-right: 20px; */
  margin-top: 10px;
}

.item_client_req_info_time {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #404246;
  width: 35px;
  margin-left: 5px;
  margin-right: 5px;
}

.item_client_req_info_price {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #404246;
  /* width: 65px; */
  margin-left: 20px;
}

.item_master_req_info_cat_name {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  color: #404246;
  /* width: 65px; */
  margin-left: 17px;
  margin-top: 20px;
  margin-bottom: 10px;
  /* position: absolute; */
  /* top: 100px;*/
}
.item_master_req_info_serives_add_id_names {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #404246;
  /* width: 65px; */
  margin-left: 17px;
  margin-top: 10px;
  /* position: absolute; */
  /* top: 100px;*/
}

.item_client_req_info_id {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #404246;
  width: 10px;
  margin-left: 15px;
}

.item_client_req_info_orderName {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #404246;
  /* width: 160px; */
  margin-left: 20px;
  margin-top: 2px;
  text-align: left;
}

.item_client_req_info_comm {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #404246;
  width: 35px;
}

.item_client_req_info_phome {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #404246;
  width: 35px;
}

.item_bshop {
  display: inline-flex;
  background: #f3f3f3;
  border-radius: 5px;
  /* padding-bottom: 7px; */
  -webkit-box-shadow: 0px 0px 8px 2px rgba(34, 60, 80, 0.16);
  -moz-box-shadow: 0px 0px 8px 2px rgba(34, 60, 80, 0.16);
  box-shadow: 0px 6px 7px 1px rgb(34 60 80 / 16%);
  /* text-align: center; */
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  border-radius: 40px 5px 5px 5px;
  border: 0.4px solid transparent;
  background-image: linear-gradient(#f3f3f3, #f3f3f3), linear-gradient(to right, #f4a582, #d432d8);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.item_masterA {
  display: block;
  background: #ffffff;
  border-radius: 5px;
  /* padding-bottom: 7px; */
  box-shadow: 0px 6px 7px 1px rgb(34 60 80 / 16%);
  /* text-align: center; */
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  border-radius: 40px 40px 40px 40px;
  border: 0.4px solid #f8f8f8;
  /* width: 180px; */
  height: auto;
}

.item_bshop:nth-child(odd) {
  display: inline-flex;
  background: #ffffff;
  border-radius: 5px;
  /* padding-bottom: 7px; */
  -webkit-box-shadow: 0px 0px 8px 2px rgba(34, 60, 80, 0.16);
  -moz-box-shadow: 0px 0px 8px 2px rgba(34, 60, 80, 0.16);
  box-shadow: 0px 6px 7px 1px rgb(34 60 80 / 16%);
  /* text-align: center; */
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  border-radius: 40px 5px 5px 5px;
  border: 0.4px solid transparent;
  background-image: linear-gradient(#f3f3f3, #f3f3f3), linear-gradient(to right, #f4a582, #d432d8);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.item_beauty_master_image {
  position: relative;
  margin-left: 10px;
  float: left;
}

.item_beauty_master_image img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-top: 10px;
}

.item_beauty_master_name {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #404246;
  /* width: 250px; */
  text-align: left;
  padding-left: 60px;
  padding-top: 8px;
  margin-left: 40px;
}

.dropdown_comm {
  /* display: inline-flex;
    align-items: center;
    justify-content: space-around; */
  width: 170px;
  height: 20px;
  /* background: #ff7a00; */
  border: 0.5px solid transparent;
  /* border-radius: 5px; */
  background: none;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  margin-top: -20px;
  margin-left: 70px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  background: linear-gradient(187deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  color: #f3f3f3;
}

.dropdown_comm_vern {
  /* display: inline-flex;
    align-items: center;
    justify-content: space-around; */
  width: 170px;
  height: 20px;
  /* background: #ff7a00; */
  border: 0.5px solid transparent;
  /* border-radius: 5px; */
  background: none;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  margin-top: -20px;
  margin-left: 170px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  color: #f3f3f3;
}

.dropdown_accept {
  width: 120px;
  height: 30px;
  /* background: #ff7a00; */
  border: 1px solid #e5e5e59e;
  /* border-radius: 5px; */
  background: none;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  margin-top: -30px;
  margin-left: 229px;
  border-radius: 5px;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  color: #f3f3f3;
  margin-bottom: 20px;
}

.modal {
  font-size: 14px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 20px;
  color: #424141;
  width: 355px;
  text-align: center;
  margin-top: 10px;
}

.header {
  width: 100%;
  /* border-bottom: 1px solid gray; */
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding: 5px;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.content {
  width: 100%;
  padding: 10px 5px;
  text-align: center;
}
.actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: 15px;
  top: 10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #ffffff;
}

.input_label {
  display: flex;
  flex-direction: column;
  width: 314px;
  margin-left: 15px;
}

.input_label label {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 6px;
  color: #404246;
  opacity: 0.7;
}

.input_label input,
.input_label textarea {
  padding: 5px 10px;
  height: 40px;
  border: 1px solid#A8A8A8;
  border-radius: 5px;
  background: none;
  opacity: 0.7;
  margin-bottom: 20px;
}

.input_label label:focus,
.input_label input :focus {
  opacity: 1;
}

.dropdown_date {
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  width: 144px;
  height: 40px;
  /* background: #ff7a00; */
  margin: auto;
  border: 0.5px solid #a8a8a8;
  border-radius: 5px;
  background: none;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
  /* margin-left: 15px; */
}

.dropdown_time {
  border-radius: 5px;
  margin-left: 20px;
  width: 144px;
  height: 40px;
  color: #404246c7;
  padding-left: 20px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 0.5px solid #a8a8a8;
  background: transparent
    url("data:image/svg+xml;utf8,<svg viewBox='0 0 16 16' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m3.626 6.832A.5.5 0 0 1 4 6h8a.5.5 0 0 1 .374.832l-4 4.5a.5.5 0 0 1-.748 0l-4-4.5z' fill='black'/></g></svg>")
    no-repeat;
  background-position: right 5px top 50%;
}

.dop_section {
  display: block;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #f3f3f3;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  box-shadow: 1px 1px 4px rgba(64, 66, 70, 0.2);
  border-radius: 100%;
  border: 1px solid #e5e5e59e;
  margin: auto;
  margin-bottom: 10px;
  margin-top: -10px;
}

.beauty_button_confirm {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 132px;
  height: 32px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #f3f3f3;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  box-shadow: 0px 0px 7px 1px #bababade;
  border-radius: 5px;
  border: 1px solid #e5e5e59e;
  margin-bottom: 15px;
}

.beauty_button1 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 112px;
  height: 32px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  margin-left: 20px;
  color: #f3f3f3;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  box-shadow: 0px 0px 7px 1px #bababade;
  border-radius: 5px;
  border: 1px solid #e5e5e59e;
  text-align: center;
  margin: auto;
  margin-top: 40px;
  margin-right: 40px;
  margin-bottom: 20px;
}

.beauty_button2 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 112px;
  height: 32px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  margin-left: 20px;
  color: #f3f3f3;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  box-shadow: 0px 0px 7px 1px #bababade;
  border-radius: 5px;
  border: 1px solid #e5e5e59e;
  text-align: center;
  margin: auto;
  margin-top: 40px;
  margin-left: 40px;
  margin-bottom: 20px;
}

.subscrib {
  margin-left: 10px;
}

.completed {
  margin-left: 10px;
}

.rating {
  margin-left: 10px;
}

.svg_icon {
  width: 13px;
  margin-right: 4px;
}

.master_short_inf {
  font-size: 10px;
  text-align: left;
  margin-top: 15px;
  display: flex;
}

.master_short_inf_salon {
  font-size: 14px;
  text-align: left;
  margin-top: 10px;
  display: flex;
  padding-left: 25px;
}

.master_short_inf_salon_phone {
  font-size: 14px;
  text-align: left;
  margin-top: 10px;
  display: flex;
  padding-left: 25px;
  margin-left: 74px;
  margin-bottom: 20px;
}

.modal_aut {
  font-size: 14px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 20px;
  color: #a8a8a8;
  width: 355px;
  text-align: center;
  height: 50px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-top: 15px;
}

.modal_comm {
  font-size: 14px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
  color: #a8a8a8;
  width: 296px;
  text-align: center;
  height: 216px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-top: 15px;
}

.input_time {
  display: contents;
  flex-direction: column;
  width: 144px;
  margin-left: 15px;
}

.input_time label {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 6px;
  color: #404246;
  opacity: 0.7;
}

.input_time input,
.input_time textarea {
  padding: 5px 10px;
  height: 40px;
  border: 1px solid#A8A8A8;
  border-radius: 5px;
  background: none;
  opacity: 0.7;
  margin-bottom: 20px;
  width: inherit;
  margin-left: 20px;
  text-align: center;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.input_time label:focus,
.input_time input :focus {
  opacity: 1;
}

.item_client_req_fio {
  text-align: center;
  margin-top: 7px;
  font-size: 12px;
  font-family: 'Gotham Pro Regular';
}

.conf_change {
  margin-top: -8px;
  border-radius: 5px;
  border: 0.4px solid transparent;
  background-image: linear-gradient(#f3f3f3, #f3f3f3), linear-gradient(to right, #f4a582, #d432d8);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  height: 30px;
  width: 130px;
  text-align: center;
  margin-left: 80px;
}
