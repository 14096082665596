.content {
  margin: 0 24px;
  padding-bottom: 291px;
  text-align: 'center';
}

.line {
  margin-top: 20px;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%) !important;
  height: 1px;
}

.highlight {
  border-radius: 100% !important;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%) !important;
  color: #fff !important;
}

.clikedDay {
  background: linear-gradient(72deg, #e5a977 1%, #dd12bb 21.56%, #fa00ff 100%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  font-weight: 500;
  padding-top: 20px;
  font-size: 15px;
  margin-left: 20px;
  margin-bottom: 10px;
}
