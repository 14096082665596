.choose-page {
  width: 100%;
  height: 100vh;
}

.choose-wrapper {
  width: 90%;
  margin: 0 auto;
  position: relative;
}

.choose-page-title {
  padding: 25px 0;
}

.choose-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.choose-items > a {
  text-decoration: none;
  color: black;
}

.choose-item-main {
  width: 160px;
  height: 180px;
  background-color: #fff;
  border-radius: 18px;
  box-shadow: 0px 0px 10px 2px rgb(139 139 255);
  text-align: center;
  margin-bottom: 22px;
  cursor: pointer;
}
.wrapper-img-choose-page {
  width: 110px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.wrapper-img-choose-page > img {
  margin-top: 18px;
  width: 110px;
  margin-bottom: 8px;
}

.popup-info-partner {
  position: absolute;
  width: 100%;
  background: #fff;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 18px;
  border-radius: 12px 12px 0 0;
  animation: show;
  animation-duration: 0.5s;
}

.popup-info-partner-text {
  font-size: 12px;
}

.popup-info-partner-text > p:nth-child(odd) {
  padding: 5px 0;
  font-weight: 700;
}

@keyframes show {
  0% {
    bottom: -150px;
    opacity: 0;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}
